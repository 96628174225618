exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-blogs-new-tsx": () => import("./../../../src/pages/blogs/new.tsx" /* webpackChunkName: "component---src-pages-blogs-new-tsx" */),
  "component---src-pages-blogs-popular-tsx": () => import("./../../../src/pages/blogs/popular.tsx" /* webpackChunkName: "component---src-pages-blogs-popular-tsx" */),
  "component---src-pages-blogs-writer-tsx": () => import("./../../../src/pages/blogs/writer.tsx" /* webpackChunkName: "component---src-pages-blogs-writer-tsx" */),
  "component---src-pages-company-profile-tsx": () => import("./../../../src/pages/company-profile.tsx" /* webpackChunkName: "component---src-pages-company-profile-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-services-shopify-tsx": () => import("./../../../src/pages/services/shopify.tsx" /* webpackChunkName: "component---src-pages-services-shopify-tsx" */),
  "component---src-templates-blog-post-contentful-tsx": () => import("./../../../src/templates/blog-post-contentful.tsx" /* webpackChunkName: "component---src-templates-blog-post-contentful-tsx" */),
  "component---src-templates-categorized-blog-post-contentful-tsx": () => import("./../../../src/templates/categorized-blog-post-contentful.tsx" /* webpackChunkName: "component---src-templates-categorized-blog-post-contentful-tsx" */),
  "component---src-templates-news-post-contentful-tsx": () => import("./../../../src/templates/news-post-contentful.tsx" /* webpackChunkName: "component---src-templates-news-post-contentful-tsx" */),
  "component---src-templates-writer-blog-post-contentful-tsx": () => import("./../../../src/templates/writer-blog-post-contentful.tsx" /* webpackChunkName: "component---src-templates-writer-blog-post-contentful-tsx" */)
}

